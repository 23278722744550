.container {
  width: 100%;
  height: 100%;
  background-color: rgb(5, 29, 54);
  padding-top: 10px;
  padding-bottom:60px;
  margin-top: 40px;
  margin-bottom: -20px;
}

.container h1 {
  text-align: center;
  color: rgb(203, 192, 255);
  font-size: 40px;
  padding-bottom: 10px;
  font-weight: 700;
  padding-top: 30px;
}

.container p {
  text-align: center;
  font-size: 1.2rem!important;
  color: white;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.flexbox-power img {
  width: 100%;
  height: auto;
  border-radius: 40px;
  transition: transform 0.3s ease;
  
}

.flexbox-power:hover{
  /* transform: scale(1.05) */
}


.flex2 img {
  height: 280px;
}

.flexbox-power {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.flexbox-power h2 {
  font-size: 24px;
  color: rgb(203, 192, 255);
  font-weight: bold;
  padding-top: 20px;
  padding-left: 30px;
  padding-bottom: 20px;
}

.flexbox-power p {
  font-size: 1.1rem!important;
  word-spacing:2px;
  line-height: 1.3;
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;
}

.flex1 {
  width: 30%;
  color: white;
  border-radius: 10px;
  background-color: #121f3e;
  border: 4px solid black;
}

.flex2 {
  width: 30%;
  color: white;
  border: 4px solid black;
  background-color: #121f3e;
  border-radius: 10px;
}

.flex3 {
  width: 30%;
  color: white;
  border: 4px solid black;
  background-color: #121f3e;
  border-radius: 10px;
}


/* Small devices (phones, 576px and down) */
@media (max-width: 576px) {
  .container h1 {
    font-size: 26px;
    padding-top: 20px;
  }

  .container p {
    font-size: 18px;
    margin-top: -6px;
    margin-bottom: 18px;
  }

  .flexbox-power {
    flex-direction: column;
    align-items: center;
  }

  .flex1, .flex2, .flex3 {
    width: 90%;
    margin-bottom: 20px;
  }

  .flex1 {
    margin-bottom: 70px; 
  }

  .flex2 {
    margin-bottom: 70px; 
  }

  .flexbox-power h2 {
    font-size: 24px;
    padding-left: 15px;
    padding-bottom: 22px;

  }

  .flexbox-power p {
    font-size: 1rem;
    line-height: 1.2;
    padding-left: 15px;
    padding-right: 15px;
  }

  .flex2 img {
    height: auto;
  }
}

/* Medium devices (tablets, 577px to 768px) */
@media (min-width: 577px) and (max-width: 768px) {
  .container h1 {
    font-size: 34px;
  }

  .container p {
    font-size: 22px;
  }

  .flexbox-power {
    flex-wrap: wrap;
  }

  .flex1, .flex2, .flex3 {
    width: 45%;
    margin-bottom: 20px;
  }

  .flexbox-power h2 {
    font-size: 28px;
    padding-left: 20px;
  }

  .flexbox-power p {
    font-size: 16px;
    line-height: 26px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .flex2 img {
    height: 220px;
  }
}

/* Large devices (desktops, 769px to 992px) */
@media (min-width: 769px) and (max-width: 992px) {
  .container h1 {
    font-size: 36px;
  }

  .container p {
    font-size: 24px;
  }

  .flexbox-power h2 {
    font-size: 30px;
    padding-left: 25px;
  }

  .flexbox-power p {
    font-size: 17px;
    line-height: 28px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .flex2 img {
    height: 250px;
  }

  .register-button {
  position: relative;
  font-size: 18px!important;
  }
}

/* Extra large devices (large desktops, 993px to 1200px) */
@media (min-width: 993px) and (max-width: 1200px) {
  .container h1 {
    font-size: 38px;
  }

  .container p {
    font-size: 25px;
  }

  .flexbox-power h2 {
    font-size: 31px;
    padding-left: 28px;
  }

  .flexbox-power p {
    font-size: 17px;
    line-height: 29px;
    padding-left: 28px;
    padding-right: 28px;
  }

  .flex2 img {
    height: 265px;
  }
}

