.container-box {
  background-color: rgb(0, 33, 65);
  padding: 40px 20px; 
}

.footer-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 16px;
  padding: 0;

}

.footer-column {
  flex: 1;
  min-width: 200px;
  margin-bottom: 20px;
  padding: 0 10px; 
}

.footer-heading {
  font-size: 22px; 
  font-weight: bold;
  margin-bottom: 15px;
  color: white;
}

.footer-column h6 {
  font-size: 18px; 
  color: white;
  margin-bottom: 10px; 
}

.footer-link {
  display: block;
  font-size: 16px; 
  color: white;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #007bff;
}

.social-container {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  flex-wrap: wrap; 
}

.social-icon {
  margin-left: -5px;
  color: #ef5a16;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #007bff;
}

.footer-bottom {
  text-align: center;
  font-weight: bold;
  font-size: 28px; 
  color: #ef5a16;
  padding-top: 20px;
  border-top: 2px solid #ddd;
}

.footer-bottom h4 {
margin-top: 2.4rem;
}

.footer-p {
  text-align: center;
  margin-top: 6px;
}

.footer-p p {
  color: white;
  text-align: center;
  font-size: 14px; 
}



@media (max-width: 1024px) {
  .footer-main {
    justify-content: space-around;
  }

  .footer-column {
    flex-basis: calc(50% - 20px);
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .footer-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .footer-column {
    text-align: left;
    margin-bottom: 0;
  }

  .footer-column:nth-child(3),
  .footer-column:nth-child(4) {
    margin-top: 20px;
  }

  .social-container {
    justify-content: flex-start;
  }

  .footer-bottom h4 {
    font-size: 26px;
    }
}

@media (max-width: 480px) {
  .container-box {
    padding: 20px 10px;
  }

  .footer-main {
    display: flex;
    flex-direction: column;
  }

  .footer-column {
    text-align: left;
    margin-bottom: 20px;
    width: 100%;
  }

  .footer-column:not(:first-child) {
    margin-top: 20px;
  }

  .footer-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .footer-link {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .footer-bottom h3 {
    font-size: 18px;
    text-align: left;
  }

  .footer-bottom h4 {
    font-size: 24px;
    }

  .footer-p {
    font-size: 12px;
    text-align: left;
  }

  .social-container {
    justify-content: flex-start;
  }

  .social-icon {
    /* margin-right: 10px; */
  }
}




