.chat-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chat-toggle {
  background-color:#ef5a16;
  font-size: 16px!important;
  color: white;
  border: none;
  padding: 10px 12px;
  border-radius: 5px;
  cursor: pointer;
  border-radius: 40px;
  
}

.chat-window {
  width: 100%;
  height: 500px!important;
  background-color: white;
  border: 4px solid #ef5a16;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding-top: px;
}

.chat-header {
  background-color:#ef5a16;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center; 
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  list-style-type: none;
}

.chat-message {
  margin-bottom: 10px;
  padding: 5px;
  background-color: #f1f1f1;
  border-radius: 12px;
}

.chat-form {
  display: flex;
  padding: 10px;
}

.chat-input {
  flex-grow: 1;
  padding: 4px;
  margin-right: 5px;
}

.chat-send {
  background-color: blue;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bolder;
}

.chat-actions {
  display: flex;
  justify-content: space-around;
  padding: 12px;
}

.chat-action-button {
  background-color: #ef5a16;
  color: white;
  border: none;
  padding: 10px 10px!important;
  cursor: pointer;
  border-radius: 3px;
}


@media screen and (min-width: 769px) and (max-width: 1200px) {
  .chat-widget {
    max-width: 350px;
    right: 30px;
    bottom: 30px;
  }

  .chat-window {
    width: 100%;
    height: 500px;
    max-height: calc(100vh - 120px);
  }

  .chat-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
  }

  .chat-input {
    flex-grow: 1;
    margin-right: 10px;
    width: calc(100% - 80px); /* Adjust based on send button width */
  }

  .chat-send {
    width: 70px; /* Fixed width for send button */
    padding: 10px;
    font-size: 14px;
  }

  .chat-actions {
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 10px;
  }

  .chat-action-button {
    flex: 0 1 auto;
    margin: 0 5px;
    padding: 8px;
    font-size: 14px;
  }
}


@media screen and (max-width: 768px) {
  .chat-widget {
    bottom: 20px;
    right: 20px;
    max-width: 320px;
  }

  .chat-window {
    width: 100%;
    height: 500px;
    max-height: calc(100vh - 100px);
  }

  .chat-toggle {
    width: auto;
  }

  .chat-form {
    flex-direction: row;
    align-items: center;
    padding: 10px;
  }

  .chat-input {
    flex-grow: 1;
    margin-right: 10px;
    margin-bottom: 0;
    width: calc(100% - 70px); /* Adjust based on send button width */
  }

  .chat-send {
    width: 60px; /* Fixed width for send button */
    padding: 8px;
    font-size: 14px;
  }

  .chat-actions {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
  }

  .chat-action-button {
    flex: 0 1 auto;
    margin: 5px;
    padding: 8px;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .chat-widget {
    max-width: 280px;
    right: 10px;
    bottom: 10px;
  }

  .chat-window {
    height: 450px;
  }

  .chat-header {
    padding: 10px;
  }

  .chat-messages {
    padding: 10px;
  }

  .chat-form {
    padding: 10px;
    flex-direction: row; /* Keep it in row to prevent button overflow */
  }

  .chat-input {
    width: calc(100% - 65px); /* Adjust based on send button width */
    margin-bottom: 0;
    font-size: 14px;
  }

  .chat-send {
    width: 60px;
    padding: 8px;
    font-size: 14px;
  }

  .chat-actions {
    padding: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .chat-action-button {
    flex: 0 0 48%;
    margin: 5px 0;
    padding: 8px;
    font-size: 13px;
  }
}

@media screen and (max-width: 320px) {
  .chat-widget {
    max-width: 250px;
  }

  .chat-window {
    height: 400px;
  }

  .chat-header {
    padding: 8px;
  }

  .chat-messages {
    padding: 8px;
  }

  .chat-form {
    padding: 8px;
  }

  .chat-input {
    width: calc(100% - 55px); /* Further adjusted for smallest screens */
    font-size: 13px;
  }

  .chat-send {
    width: 50px;
    padding: 6px;
    font-size: 13px;
  }

  .chat-actions {
    padding: 8px;
  }

  .chat-action-button {
    padding: 6px;
    font-size: 12px;
  }
}


