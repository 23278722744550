
.heading {
  height: 100px;
  background-color: rgb(9, 9, 146);
  color: yellow;
  font-size: 2rem!important;
  font-weight: bolder;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
  margin-top: -1px;
    margin-bottom: -1px;
}

.second-checkout-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  margin-bottom: -40px;
  background-color: rgb(0, 33, 65);
  padding-top: 2.5rem;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.second-checkout-container a {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.875rem;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.checkout-first-div {
  width: 400px;
  background-color:#121f3e ;
  /* background-color: rgb(13, 37, 62); */
  padding-left: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  margin-top: 1.25rem;
  margin-left: 1.25rem;
  margin-bottom: 1.25rem;
  border-radius: 20px;
  /* border-width: 20px; */
  color: white;
  border: 6px solid #091430;
}

.checkout-second-div {
  background-color: #121f3e;
  width: 400px;
  padding-left: 1.625rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border-radius: 20px;
  border: 6px solid #091430;
}

.checkout-third-div {
  background-color:#121f3e;
  width:400px;
  padding-left: 1.625rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  margin-top: 1.25rem;
  margin-right: 1.25rem;
  margin-bottom: 1.25rem;
  border-radius: 20px;
  border: 6px solid #091430;
}


.second-checkout-container img {
  width: 100%;
padding-right: -10px;
padding-bottom: 20px;
border-radius: 30px;
}

@media (max-width: 576px) {
  .heading {
    font-size: 1.5rem !important;
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .second-checkout-container {
    flex-direction: column;
    gap: 2px;
    padding-top: 1.5rem;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: -40px;
  }

  .second-checkout-container a {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .checkout-first-div,
  .checkout-second-div,
  .checkout-third-div {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 1rem;
    border-width: 4px;
    border-radius: 15px;
  }

  .checkout-first-div {
    margin-top: 0.75rem;
  }

  .checkout-third-div {
    margin-bottom: 0.75rem;
  }

  .second-checkout-container img {
    padding-right: 0;
    padding-bottom: 15px;
    border-radius: 20px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .heading {
    font-size: 1.75rem !important;
    padding-top: 30px;
    padding-bottom: 16px;
  }

  .second-checkout-container {
    flex-direction: column;
    gap: 0px;
    padding-top: 2rem;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: -40px;
  }

  .second-checkout-container a {
    font-size: 1.05rem;
    line-height: 1.6rem;
  }

  .checkout-first-div,
  .checkout-second-div,
  .checkout-third-div {
    width: 90%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding: 1.2rem;
    border-width: 5px;
    border-radius: 18px;
  }

  .checkout-first-div {
    margin-top: 1rem;
  }

  .checkout-third-div {
    margin-bottom: 1rem;
  }

  .second-checkout-container img {
    width: 100%;
    padding-right: 0;
    padding-bottom: 18px;
    border-radius: 25px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .heading {
    font-size: 1.9rem !important;
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .second-checkout-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding-top: 2.25rem;
    padding-bottom: 45px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: -35px;
  }

  .second-checkout-container a {
    font-size: 1.1rem;
    line-height: 1.7rem;
  }

  .checkout-first-div,
  .checkout-second-div,
  .checkout-third-div {
    width: calc(50% - 20px);
    max-width: 350px;
    margin: 0.75rem 10px;
    padding: 1.3rem;
    border-width: 5px;
    border-radius: 18px;
  }

  .checkout-first-div {
    margin-top: 1rem;
  }

  .checkout-third-div {
    margin-bottom: 1rem;
    width: calc(100% - 20px);
    max-width: 720px;
  }

  .second-checkout-container img {
    padding-right: 0;
    padding-bottom: 20px;
    border-radius: 25px;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .heading {
    font-size: calc(1.5rem + 1vw) !important;
    margin-top: -1px;
    margin-bottom: -1px;
    padding-top: calc(30px + 2vw);
    padding-bottom: calc(15px + 1vw) !important;
  }

  .second-checkout-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding: 2rem 20px 40px;
    margin-bottom: -30px;
  }

  .second-checkout-container a {
    font-size: calc(1rem + 0.2vw);
    line-height: 1.6;
  }

  .checkout-first-div,
  .checkout-second-div,
  .checkout-third-div {
    flex-basis: calc(50% - 10px);
    max-width: none;
    margin: 0;
    padding: 1rem;
    border-width: 4px;
    border-radius: 15px;
  }

  .checkout-third-div {
    flex-basis: 100%;
    margin-top: 20px;
  }

  .second-checkout-container img {
    padding-right: 0;
    padding-bottom: 15px;
    border-radius: 20px;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}


@media screen and (min-width: 1401px) {
  .second-checkout-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .checkout-first-div,
  .checkout-second-div,
  .checkout-third-div {
    width: calc(33.33% - 20px); /* Subtracting 20px to account for the gap */
    max-width: none; /* Remove any max-width constraints */
  }

  /* Adjust the container to allow for growth */
  .second-checkout-container {
    max-width: none;
    width: 100%;
  }
}

/* For even larger screens, you might want to cap the growth */
@media screen and (min-width: 1800px) {
  .second-checkout-container {
    /* max-width: 1800px; */
    margin-left: auto;
    margin-right: auto;
  }
}