/* Order.css */

.contact-button {
  font-size: 20px;
  background-color: yellow;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  border-radius: 30px;
}

.contact-button:hover {
  background-color: #0056b3;
}



.contact-button {
color: red;
}
