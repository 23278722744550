* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper1 {
  background-color: #fdd700;
  margin-bottom: -64px;
  padding-bottom: 64px; 
}

.wrapper1 h1 {
  font-size: 3rem;
  padding-top: 1em;
  font-weight: bold;
  color: black;
  text-align: center;
}

.wrapper1 p {
  font-size: 1.3rem;
  color: black;
  text-align: center;
  padding: 1rem 1.25rem 1.25rem;
  margin-bottom: 1.25rem;
}

.flex-master {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 2em;
  margin: 2em 3em 4em;
}

.flex-master h2 {
  font-size: 1.7rem;
  margin-left: 20px!important;
  margin: 1rem;
  color: blueviolet;
}

.flex-master p {
  text-align: left;
  padding: 1.25rem;
  color: white;
  font-size: 1.1rem;
  line-height: 1.5;
}

.wrapper1 img {
  width: 100%;
  border-radius: 12px;
}

.div1, .div2, .div3 {
  background-color: #15212a;
  width: 28%;
  height: 550px;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
}

.div1 img, .div2 img, .div3 img {
  height: 40%;
  object-fit: cover;
}

.domain {

}

.flex-master button, .register-button {
  font-size: 1rem!important;
  font-weight: bold;
  background-color: #fdd700;
  margin: 20px 1rem;
  padding: 1rem 0.5rem;
  border-radius: 20px;
  cursor: pointer;
  align-self: center;
}

.flex-master button:hover {
  background-color: #ef5a16;
  color: black;
}

@media screen and (max-width: 767px) {
  .wrapper1 h1 {
    font-size: 2rem;
    padding-top: 0.5em;
  }

  .wrapper1 p {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .flex-master {
    flex-direction: column;
    align-items: center;
    margin: 1em;
    padding-bottom: 1em;
  }

  .div1, .div2, .div3 {
    width: 100%;
    height: auto;
    margin-bottom: 2em;
  }

  .flex-master h2 {
    font-size: 1.4rem;
    margin-left: 10px !important;
  }

  .flex-master p {
    font-size: 1rem;
    padding: 1rem;
  }

  .flex-master button, .register-button {
    font-size: 0.9rem !important;
    padding: 0.8rem 0.4rem;
    margin: 15px 0.5rem;
  }

  .div1 img, .div2 img, .div3 img {
    height: 260px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .flex-master {
    margin: 2em 2em 4em;
  }

  .div1, .div2, .div3 {
    width: 30%;
    height: auto;
    min-height: 620px; /* Increased to accommodate content */
    display: flex;
    flex-direction: column;
  }

  .flex-master h2 {
    font-size: 1.5rem;
    margin-left: 15px !important;
  }

  .flex-master p {
    font-size: 0.95rem;
    flex-grow: 1; /* Allow paragraph to grow and push button down */
    padding: 1rem 1rem 0.5rem; /* Reduced bottom padding */
  }

  .flex-master button, .register-button {
    font-size: 0.85rem !important;
    padding: 0.7rem 0.3rem;
    margin: 10px 0.5rem;
    align-self: center;
    position: relative;
    bottom: 5px; /* Adjust button position */
  }

  .div1 img, .div2 img, .div3 img {
    height: 35%; /* Adjusted image height */
  }
}



/* @media (min-width: 768px) and (max-width: 991px) {
  .flex-master {
    margin: 2em 2em 4em;
  }

  .div1, .div2, .div3 {
    width: 30%;
  }
  .flex-master button, .register-button {
    font-size: 0.9rem !important;
    padding: 0.8rem 0.4rem;
    margin: 15px 0.5rem;
  }
} */

@media (min-width: 992px) {
  .flex-master {
    margin: 2em 1.5em 4em;
  }

  .div1, .div2, .div3 {
    width: 31%;
  }
}

@media (min-width: 1200px) {
  .flex-master {
    margin: 2em 1em 4em;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .div1, .div2, .div3 {
    width: 32%;
  }
}

@media (min-width: 1400px) {
  .flex-master {
    max-width: 1400px;
  }

  .div1, .div2, .div3 {
    width: 30%;
    max-width: 420px;
  }
}

@media (min-width: 1600px) {
  .flex-master {
    max-width: 1600px;
  }

  .div1, .div2, .div3 {
    width: 31%;
    max-width: 480px;
  }
}

@media (min-width: 1800px) {
  .flex-master {
    max-width: 1800px;
  }

  .div1, .div2, .div3 {
    width: 32%;
    max-width: 540px;
  }

  .wrapper1 h1 {
    font-size: 4.5rem;
  }

  .wrapper1 p {
    font-size: 1.4rem;
  }

  .flex-master h2 {
    font-size: 1.9rem;
  }

  .flex-master p {
    font-size: 1.2rem;
  }

  .flex-master button, .register-button {
    font-size: 1.1rem !important;
  }
}

@media (min-width: 2001px) {
  .flex-master {
    max-width: none; 
    width: 90vw; 
    margin-left: auto;
    margin-right: auto;
  }

  .div1, .div2, .div3 {
    width: calc(30% + 1vw); 
    max-width: none; 
  }

  .wrapper1 h1 {
    font-size: calc(5rem + 0.5vw); 
  }

  .wrapper1 p {
    font-size: calc(1.5rem + 0.2vw); 
  }

  .flex-master h2 {
    font-size: calc(2rem + 0.3vw); 
  }

  .flex-master p {
    font-size: calc(1.3rem + 0.1vw); 
  }

  .flex-master button, .register-button {
    font-size: calc(1.2rem + 0.1vw) !important; 
    padding: calc(1.2rem + 0.2vw) calc(0.6rem + 0.1vw); 
  }

  .flex-master h2 {
    padding-left: calc(1rem + 0.5vw);
    padding-right: calc(1rem + 0.5vw);
  }

  .flex-master p {
    padding: calc(1.25rem + 0.5vw);
  }

  .div1 img, .div2 img, .div3 img {
    height: calc(40% + 2vw); 
  }
  .flex-master button, .register-button {
    font-size: 1.1rem !important;
    position: relative;
    top: -6%;
  }
}
