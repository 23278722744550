body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* You can add additional global styles here if needed */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
}

/* Example of setting different weights */
h1 { font-weight: 700; } /* Bold */
h2 { font-weight: 500; } /* Medium */
p { font-weight: 400; }  /* Regular */