.global {
  background-color: rgb(8, 17, 24);
  padding-top: 2rem;
  padding-bottom: 0.6rem;
  line-height: 1.2;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: yellow;
  margin-bottom: rem;
}

.grid-link-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  padding: 1.25rem;
  background-color: rgb(8, 17, 24);
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.grid-item {
  background-color: rgb(0, 0, 72);
  border-radius: 8px;
  border: 4px solid black;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.grid-item img {
  width: 100%;
  height: 300px !important;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease;
}

.grid-item:hover img {
  transform: scale(1.1);
}

.text-content {
  margin-left: -10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 1.25rem;
  transform: translateY(40%);
  transition: transform 0.3s ease;
}

.grid-item:hover .text-content {
  transform: translateY(0);
}

.text-content h3 {
  padding-bottom: 10px;
  margin: 0 0 0px 0;
  font-size: 1.6em !important;
  color: rgb(85, 189, 229) !important;
}

.text-content p {
  margin: 0 0 15px 0;
  word-spacing: 1px;
  font-size: 1.1em;
  line-height: 1.3;
  padding-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: -webkit-line-clamp 0.3s ease;
}

.grid-item:hover .text-content p {
  -webkit-line-clamp: unset;
}

.read-more {
  display: inline-block;
  color: #ef5a16;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.9em;
  transition: color 0.3s ease;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.grid-item:hover .read-more {
  opacity: 1;
  visibility: visible;
}

.read-more:hover {
  color: #ffd700;
}

.read-more i {
  margin-left: 5px;
  margin-top: 1.25rem;
  transition: transform 0.3s ease;
}

.read-more:hover i {
  transform: translateX(5px);
}

.button-container {
  display: flex;
  justify-content: center;
  background-color: rgb(8, 17, 24);
  padding-bottom: 20px;
}

.link-button {
  padding: 1rem 1.875rem;
  margin-bottom: 1.25rem;
  margin-top: -10px;
  font-size: 1.6rem;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 30px;
  background: transparent;
  border: 4px solid #ef5a16;
  font-weight: bold;
  transition: all 0.3s ease;
  z-index: 1;
}

.link-button:hover {
  background: #ef5a16; 
  color: white; 
  border-color: #ef5a16;
}

@media (max-width: 576px) {
  .global {
    font-size: 24px;
    padding-top: 1rem;
    padding-bottom: 0.3rem;
  }

  .grid-link-container {
    grid-template-columns: 1fr;
    gap: 30px;
    padding-top: 1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: x;
  }

  .grid-item img {
    height: 300px;
  }

  .text-content h3 {
    font-size: 1.4em !important;
  }

  .text-content p {
    font-size: 1rem;
    padding-bottom: 16px;
  }

  .link-button {
    font-size: 1.2rem;
    padding: 12px 24px;
    position: relative;
    top: -20px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .global {
    font-size: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .global2 {
    font-size: 24px;
  }

  .grid-link-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-item img {
    height: 250px;
  }

  .link-button {
    font-size: 1.4rem;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .global {
    font-size: 40px;
  }

  .global2 {
    font-size: 26px;
  }

  .grid-link-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-item img {
    height: 275px;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .global {
    font-size: 40px;
  }

  .global2 {
    font-size: 28px;
  }

  .grid-item img {
    height: 280px;
  }
}