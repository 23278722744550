body {
  margin: 0;
  padding: 0;
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  padding: 1.25rem;
  background-color: rgb(0, 33, 65);
  height: auto;
  margin-top: 0px;
}

.contact-info {
  flex: 1;
  padding: 1.25rem;
  max-width: 600px;
}

.contact-info p {
  font-size: 18px;
  padding-top: 14px;
  padding-left: 1px;
  margin-left: 2px;
  margin-bottom: -10px;
}

.contact-info h2 {
font-size: 1.8rem!important;
margin-left: 1.85rem;
margin-top: 1.875rem;
color: #ef5a16;
position: relative;
  top: -6px;
}

.first-p {
color: white;
margin-top: -1.25rem;
margin-left: 1.25rem;
font-size: 1.625rem;
padding-top: 1rem;
padding-bottom: 1.85rem!important;
word-spacing: -0.6px; 

}

.second-p {
font-size: 1.25rem;
padding-top: 1.875rem;
margin-left: 1.25rem;
padding-bottom: 1.25rem;
color: white;
position: relative;
  top: -1.25rem;
}

.contact-info p{
font-size: 1.25rem;
color:white;
padding: 1.25rem;
}

.contact-info .number {
  background-color: orangered;
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
  margin-right: 5px;
  border-radius: 1.25rem;
  padding: 4px;
}

.partner {
  top: -4%;
margin-left: -12px;
}

.partner2 {
margin-left: -12px;
position: relative;
  top: -2rem;
}

.footerc {
color: white;
position: relative;
bottom: 28px;
margin-left: 10px;
  top: -36px;
}

.address-container {
color: yellow!important;
padding-left: 1.25rem;
font-weight: bold;
position: relative;
top: -2%;
}

.address-container img {
width: 80px;
height: 80px;
margin-top: 4px;
margin-bottom: -10px;
margin-right: 10px;
padding-top: 18px;
vertical-align: middle; 
}

.zip-code {
  font-weight: bold;
  margin-left: 90px;
  position: relative;
top: -22px;
}



.form-section {
  flex: 1;
  display: flex;
  justify-content: flex-end; /* Align the form to the right */
  background-color: white; /* Background color for the form section */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 1.875rem;
  margin-left: 4rem;
  margin-right: 1.25rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-right: 1.875rem; 
  padding-top: 2rem;
  border: 30px solid rgb(203, 192, 255); 
}


.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 550px; /* Control the max width of the form */
  padding: 1.25rem; /* Add padding to form */
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}

.form-control {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: #f9f9f9; 
}

textarea.form-control {
  height: 100px;
}

.btn {
  background-color: #ef5a16;
  color: black;
  padding: 1.25rem 30px;
  font-size: 1.25rem;
  border: none;
  border-radius: 20px;
  border: 4px solid #ef5a16;
  cursor: pointer;
}

.btn:hover {
  background-color: red;
}


/* Small devices (phones, 576px and down) */
@media (max-width: 576px) {
  .contact-container {
    flex-direction: column;
    margin-top: -3.125rem;
  }

  .contact-info, .form-section {
    max-width: 100%;
    margin: 1rem 0;
  }

  .form-section{
    margin-top: -1.25rem;
    margin-bottom: 2.5rem;
    }

  .contact-info h2 {
    margin-bottom: 20px;
    font-size: 28px;
    margin-left: -12px;
  }

  
  .contact-info p {
    font-size: 18px;
    line-height: 1.2;
    word-spacing: 1px;
    padding-top: 14px;
    padding-left: 1px;
    margin-left: -14px;
    margin-bottom: -10px;
  }

  .address-container img {
    width: 60px;
    height: 60px;
  }

  .form-section {
    padding-right: 15px;
    border-width: 15px;
  }

  .btn {
    padding: 15px 25px;
    font-size: 18px;
  }
}

/* Medium devices (tablets, 577px to 768px) */
@media (min-width: 577px) and (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    margin-top: -3.125rem;
  }

  .contact-info, .form-section {
    max-width: 100%;
    margin: 1rem 0;
  }

  .form-section{
  margin-top: -1.25rem;
  margin-bottom: 2.5rem;
  }

  .contact-info h2 {
    font-size: 28px;
    margin-left: -12px;
  }

  .contact-info p {
    font-size: 1.25rem;
    line-height: 1.2;
    word-spacing: 1px;
    padding-top: 14px;
    padding-left: 1px;
    margin-left: -14px;
    /* padding: 10px; */
    margin-bottom: -10px;
  }


  .form-section {
    padding-right: 1.25rem;
    border-width: 1.25rem;
  }
}

/* Large devices (desktops, 769px to 992px) */
@media (min-width: 769px) and (max-width: 992px) {
  .contact-container {
    margin-top: -2.5rem;
  }
  
  .contact-info {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .form-section {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0.2rem;
    margin-right: 1.675rem!important;
    margin-top: -1.25rem;
  margin-bottom: 2.5rem;
  }

  .form {

  }

  .contact-info h2 {
    font-size: 34px;
    padding-bottom: 10px;
    margin-left: 10px;
  }

  .contact-info p {
    font-size: 1.25rem;
    padding-top: 14px;
    line-height: 1.2;
    word-spacing: 1px;
    padding-left: px;
    /* padding: 10px; */
    margin-left: -10px;
    margin-bottom: -10px;
  }
  }


@media (min-width: 993px) and (max-width: 1200px) {
  .contact-info {
    flex: 0 0 45%;
  }

  .form-section {
    flex: 0 0 50%;
    margin-left: 2rem;
  }

  .contact-info h2 {
    font-size: 27px;
    margin-left: 2px;
    padding-bottom: 10px;
  }


  .contact-info p {
    font-size: 1.25rem;
    padding-top: 14px;
    padding-left: 1px;
    margin-bottom: -10px;
  }
}

@media (min-width: 1400px) {
  .contact-container {
    padding: 2rem;
  }

  .contact-info {
    max-width: 700px;
  }

  .contact-info h2 {
    font-size: 2.5rem;
    margin-top: 2.5rem;
  }

  .first-p {
    font-size: 1.875rem;
    line-height: 1.2;
    word-spacing: 1px;
    padding-top: 1.5rem;
    padding-bottom: 2.25rem !important;
  }

  .second-p {
    font-size: 1.5rem;
    padding-top: 2.25rem;
    padding-bottom: 1.5rem;
  }

  .contact-info p {
    font-size: 1.5rem;
    padding: 1.5rem;
  }

  .form-section {
    margin-left: 5rem;
    margin-right: 2rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding-right: 2.25rem;
    padding-top: 2.5rem;
  }

  .form {
    max-width: 650px;
  }

  .form-control {
    padding: 1.25rem;
    font-size: 1.125rem;
  }

  textarea.form-control {
    height: 120px;
  }
}
