.ruler {
  background-color: rgb(21, 33, 42);
  padding: 0 20px;
}



.grid-container1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 0;
}

.grid-box {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.grid-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.upper-div, .lower-div {
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.upper-div {
  background-color: rgb(16, 25, 32);
  flex: 1;
  align-items: flex-start;
  width: 100%;
  border: 4px solid rgb(21, 33, 42);
  border-radius: 4px;
}

.lower-div {
  background-color: rgb(85, 189, 229);
  flex: 1;
  width: 98%;
  margin-left: 4px;
  align-items: flex-start;
}

.upper-div:hover {
  /* background-color: rgb(24, 38, 47); */
  /* border-color: rgb(24, 38, 47); */
}

.lower-div:hover {
  /* background-color: rgb(105, 209, 249); */
}

.upper-div img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 15px;
  border-radius: 16px;
  transition: transform 0.3s ease;
}

.upper-div img:hover {
  transform: scale(1.05);
}

h2 {
  margin: 10px 0;
  color: rgb(85, 189, 229);
  font-size: 1.4rem!important;
  font-weight: 700;
  width: 100%;
  text-align: left;
}

h3 {
  color: white;
  width: 100%;
  text-align: left;
}

p {
  color: white;
  font-size: 1.1rem!important;
  line-height: 1.5;
  margin: 5px 0;
  text-align: left;
  width: 100%;
}

button {
  background-color: #fdd700;
  color: black;
  border: none;
  padding: 20px 30px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 15px;
  transition: background-color 0.3s ease;
  align-self: flex-start;
}

button:hover {
  background-color: red;
}

.feature-item {
  text-align: left;
  font-size: 1.2rem;
  line-height: 1;
  margin: 5px 0;
  font-weight: 400;
  color: black;
  background-color: rgb(85, 189, 229);
  /* border-radius: 4px; */
  /* border: 8px solid rgb(85, 189, 229); */
  cursor: pointer;
  width: calc(100% - 16px);
  /* transition: background-color 0.3s ease, border-color 0.3s ease; */
}

.feature-item:hover {
  background-color: rgb(105, 209, 249);
  border-color: rgb(105, 209, 249);
}


/* Media Queries */
@media (min-width: 1200px) {
  .ruler {
    padding: 0 40px; 
  }

  .grid-container1 {
    gap: 30px; 
  }

  .grid-box {
    /* Add any specific styles for larger grid boxes if needed */
  }

  h2 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1.1rem;
  }

  .upper-div img {
    height: 250px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ruler {
    padding: 0 30px;
  }

  .grid-container1 {
    gap: 25px;
  }
}


@media (min-width: 768px) and (max-width: 991px) {
  .grid-container1 {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .ruler {
    padding: 0 20px;
    padding-bottom: 2.8rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  .upper-div img {
    height: 180px;
  }

  /* Center the last grid item */
  .grid-container1 > *:last-child:nth-child(odd) {
    grid-column: 1 / span 2;
    justify-self: center;
    max-width: calc(50% - 10px);
  }
}


@media (max-width: 767px) {
  .ruler {
    padding: 0 15px;
  }

  .grid-container1 {
    grid-template-columns: 1fr;
    padding: 15px 0;
  }

  .grid-box {
    max-width: 100%; /* Allow full width on mobile */
  }

  .upper-div, .lower-div {
    padding: 15px;
  }

  .upper-div img {
    height: 220px;
  }
}

@media (max-width: 575px) {
  .ruler {
    padding: 0 10px;
  }

  .grid-container1 {
    padding: 10px 0;
  }

  h2 {
    font-size: 1.3rem;
  }

  p {
    font-size: 0.9rem;
  }

  button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .upper-div img {
    height: 180px;
  }

  .lower-div p {
    font-size: 0.9rem;
    border-width: 6px;
    width: calc(100% - 12px);
  }
}






